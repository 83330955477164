import { axiosInstance } from "./axiosSetup";
import { API_PATHS } from './apiPaths';

export const getPublishers = (startrow = 1, maxrows = 50, search = '') => {
    return axiosInstance.get(API_PATHS.GET_PUBLISHERS(startrow, maxrows, search));
};

export const getProfilesByPublisher = (publisherId) => {
    return axiosInstance.get(API_PATHS.GET_PROFILES_BY_PUBLISHER(publisherId));
};

export const getUsersByPublisher = (publisherId) => {
    return axiosInstance.get(API_PATHS.GET_USERS_BY_PUBLISHER(publisherId));
};

export const getPublisher = (id) => {
    return axiosInstance.get(API_PATHS.GET_PUBLISHER(id));
};

export const createPublisher = (data) => {
    return axiosInstance.post(API_PATHS.CREATE_PUBLISHER, data);
};

export const updatePublisher = (id, data) => {
    return axiosInstance.patch(API_PATHS.UPDATE_PUBLISHER(id), data);
};
