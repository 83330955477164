import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { matchLogTabColumns } from '../columns';
import { convertISOToReadableDate, getDupSubMatchLog, showErrorToast } from "../../../helpers";

const MatchLogTab = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getDupSubMatchLog();
                setData(data);
            } catch (error) {
                showErrorToast();
            }
        }

        fetchData();
    }, []);

    const mappedData = data?.map(x => {
        return {
            timestamp: convertISOToReadableDate(x?.trigger?.submitted),
            trigger: x?.trigger?.publisher_name + ' ' + x?.trigger?.journal,
            manuscriptId: x?.trigger?.manuscript_id,
            matches: (() => {
                const content = x?.matches?.map(match => match.publisher_name).join(', ') || '';
                return content.length > 100 ? content.slice(0, 97) + '...' : content;
            })()
        }
    })

    return (
        <DataTable
            columns={matchLogTabColumns}
            rows={mappedData}
        />
    );
};

export default MatchLogTab;