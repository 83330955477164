import React, { useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTooltip } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import Dialog from "../../../components/Dialog/Dialog";
import { usePublisherUsers } from "./hooks/usePublisherUsers";
import Pagination from "../../../components/Pagination/Pagination";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import DataTable from "../../../components/table/DataTable";
import PublisherUserCreateModal from "./components/PublisherUserCreateModal";
import PublisherUserEditModal from "./components/PublisherUserEditModal";

export const columns = [
  {
    label: "ID",
    field: "id",
  },
  {
    label: "Username",
    field: "username",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "",
    field: "editAction",
    width: 20,
  },
  {
    label: "",
    field: "deleteAction",
    width: 20,
  },
];

const PublisherUsersTab = () => {
  let { id } = useParams();
  const {
    usersData,
    error,
    currentPage,
    pageCount,
    setCurrentPage,
    handleDeleteUser,
    fetchData,
  } = usePublisherUsers(id);

  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [userToEditId, setUserToEditId] = useState(null);
  const [userToDeleteId, setUserToDeleteId] = useState(null);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleCreateUserClick = () => {
    setIsNewUserModalOpen(true);
  };

  const handleDeleteUserConfirm = async () => {
    await handleDeleteUser(userToDeleteId);
    setUserToDeleteId(null);
  };

  const usersDataWithActions = usersData?.map(user => ({
    ...user,
    editAction: (
      <MDBTooltip tag="div" title="Edit User" placement="top">
        <MDBBtn
          color="secondary"
          floating
          onClick={() => setUserToEditId(user.id)}
        >
          <MDBIcon fas icon="edit" />
        </MDBBtn>
      </MDBTooltip>
    ),
    deleteAction: (
      <MDBTooltip tag="div" title="Remove User" placement="top">
        <MDBBtn
          color="danger"
          floating
          onClick={() => setUserToDeleteId(user.id)}
        >
          <MDBIcon far icon="trash-alt" />
        </MDBBtn>
      </MDBTooltip>
    )
  }));

  return (
    <>
      <Dialog
        onConfirm={async () => await handleDeleteUserConfirm(userToDeleteId)}
        isOpen={!!userToDeleteId}
        setIsOpen={setUserToDeleteId}
        dialogText={"Are you sure you want to delete this user?"}
      />
      <PublisherUserCreateModal
        isOpen={isNewUserModalOpen}
        onClose={() => setIsNewUserModalOpen(false)}
        onSuccessSubmit={fetchData}
      />
      <PublisherUserEditModal
        itemId={userToEditId}
        onClose={() => setUserToEditId(null)}
        onSuccessSubmit={fetchData}
      />
      <MDBRow className="mb-2">
        <MDBCol className="text-end">
          <MDBBtn
            color="primary"
            size="md"
            onClick={handleCreateUserClick}
          >
            <MDBIcon icon="plus-circle" className="me-2" />
            New user
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && usersData && (
        <>
          <DataTable rows={usersDataWithActions} columns={columns} />
          <MDBRow>
            <MDBCol className="mt-2">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            </MDBCol>
          </MDBRow>
        </>
      )}
    </>
  );
};

export default PublisherUsersTab;
