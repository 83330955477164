import { itemsPerPage } from "../constants";

export const API_PATHS = {
    // Watchlist
    WATCHLIST: (type, startrow, maxrows) => `/watchlist?type=${type}&startrow=${startrow}&maxrows=${maxrows}`,
    EXPORT_WATCHLIST: (type) => `/watchlist/export?type=${type}`,
    IMPORT_FILE_PRESIGN: "/watchlist/import?stage=presign",
    IMPORT_FILE_PREVIEW: (fileName, type) => `/watchlist/import?stage=test&file=${fileName}&type=${type}`,
    IMPORT_FILE_CONFIRM: (fileName, type) => `/watchlist/import?stage=confirm&file=${fileName}&type=${type}`,
    GET_CHECKER_LIST: (startrow, maxrows, search) => `/watchlist/lookup?startrow=${startrow}&maxrows=${maxrows}&query=${search}`,
    DUPLICATE_WATCHLIST_RECORD: (id) => `/watchlist/duplicate/${id}`,

    // Users
    GET_USERS: (startrow, maxrows, search) => `/admin/user/list?start_row=${startrow}&max_rows=${maxrows}&email=${search}`,
    GET_USER: (id) => `/admin/user/extract/${id}`,
    UPDATE_USER: (id) => `/admin/user/${id}`,
    DELETE_USER: (id) => `/admin/user/${id}`,
    CREATE_USER: "/admin/user",

    // Publishers
    GET_PUBLISHERS: (startrow, maxrows, search) => `/admin/publisher/list?start_row=${startrow}&max_rows=${maxrows}&name=${search}`,
    GET_PROFILES_BY_PUBLISHER: (publisherId) => `/admin/publisher/profiles/${publisherId}`,
    GET_USERS_BY_PUBLISHER: (publisherId) => `/admin/publisher/users/${publisherId}`,
    GET_PUBLISHER: (id) => `/admin/publisher/extract/${id}`,
    CREATE_PUBLISHER: "/admin/publisher/create",
    UPDATE_PUBLISHER: (id) => `/admin/publisher/${id}`,

    // Profiles
    GET_PROFILES: (startrow, maxrows, search) => `/admin/profile/list?start_row=${startrow}&max_rows=${maxrows}&profile_name=${search}`,
    GET_PROFILE: (profileId) => `/admin/publisher/extract/profile/${profileId}`,
    CREATE_PROFILE: "/admin/profile",
    UPDATE_PROFILE: (profileId) => `/admin/profile/${profileId}`,
    DELETE_PROFILE: (profileId) => `/admin/profile/${profileId}`,

    // Duplicate Submissions
    GET_DUP_SUB_CORPUS: "/admin/dupsub/corpus",
    GET_DUP_SUB_REQUESTS: (timeRange) => `/admin/dupsub/requests?timeRange=${timeRange}`,
    GET_DUP_SUB_RECENT: (limit, lastEventTimestamp) => `/admin/dupsub/recent?limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`,
    GET_DUP_SUB_MATCH_LOG: `/reports/dupsub/matches/latest?startRow=0&maxRows=25`,
    GET_DUP_SUB_STATISTICS_PER_WEEK: "/admin/dupsub/per-week",
    GET_DUP_SUB_CORPUS_STATISTICS_PER_WEEK: "admin/dupsub/corpus-per-day",

    // On-Demand Screening
    GET_ON_DEMAND_SCREENING_PUBLISHERS: "/admin/ods/publishers",
    GET_ON_DEMAND_SCREENING_SUBMITTED: (timeRange) => `/admin/ods/users?timeRange=${timeRange}`,
    GET_ON_DEMAND_SCREENING_RECENT: (limit, lastEventTimestamp) => `/admin/ods/recent?limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`,
    GET_ON_DEMAND_SCREENING_ACTION_STATS: (timeRange) => `/admin/ods/action-stats?timeRange=${timeRange}`,
    GET_ON_DEMAND_SCREENING_STATISTICS_PER_WEEK: "/admin/ods/per-week",

    // Ambient Screening
    GET_AMBIENT_SCREENING_SUBMITTED: (timeRange) => `/admin/as/users?timeRange=${timeRange}`,
    GET_AMBIENT_SCREENING_RECENT: (limit, lastEventTimestamp) => `/admin/as/recent?limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`,
    GET_AMBIENT_SCREENING_STATISTICS_PER_WEEK: '/admin/as/per-week',
    GET_AMBIENT_SCREENING_JOURNALS: '/admin/as/per-journal',
    GET_AMBIENT_SCREENING_ACTION_STATS: (timeRange) => `/admin/as/action-stats?timeRange=${timeRange}`,
    GET_AMBIENT_SCREENING_PUBLISHERS: '/admin/as/publishers',

    // API Access
    GET_API_ACCESS_STATS_EXPORT_URL: (period, publisher_id) => `/admin/access/export?period=${period}&publisher_id=${publisher_id}`,
    GET_API_ACCESS_RECENT_ACTIVITY: (limit, lastEventTimestamp) => `/admin/access/get?period=latest&limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`,
    GET_API_ACCESS_REQUESTS: (timeRange) => `/reports/external/requests?timeRange=${timeRange}`,

    // Reporting
    GET_COGNITO_USERS: "/admin/users/monitoring",
    GET_DUP_SUB_JOURNALS: "/admin/dupsub/journals",
    GET_DUP_SUB_MATCHES_AVERAGE: (timeRange) => `/admin/dupsub/average?timeRange=${timeRange}`,
    GET_DUP_SUB_MATCHES_PER_PUBLISHER: (timeRange) => `/admin/dupsub/per-publisher?timeRange=${timeRange}`,
    GET_DUP_SUB_MATCHES_PER_JOURNAL: (timeRange) => `/admin/dupsub/per-journal?timeRange=${timeRange}`,
    GET_PUBLISHER_PROFILES_MONITORING: "/admin/publisher-profile/list",

    // External Keys
    GET_KEYS: (startrow, maxrows, search) => `/admin/external-keys/list?start_row=${startrow}&max_rows=${maxrows}&key_name=${search}`,
    GET_KEY: (id) => `/admin/external-keys/extract/${id}`,
    CREATE_KEY: "/admin/external-keys",
    UPDATE_KEY: (id) => `/admin/external-keys/${id}`,
    DELETE_KEY: (id) => `/admin/external-keys/${id}`,

    // Journals
    GET_JOURNALS_LIST: "/admin/journals/list",
    GET_JOURNAL_SUBJECTS: "/admin/journal-subjects/list",
};
