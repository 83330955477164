import { useState, useEffect } from 'react';
import { itemsPerPage as itemsPerPageDefault } from '../../../constants';
import { createKey, deleteKey, getKeys, showErrorToast, showSuccessToast, updateKey } from '../../../helpers';

const useKeys = ({ currentPage = 0, doInitialFetch = true, itemsPerPage = itemsPerPageDefault }) => {
    const [keysData, setKeysData] = useState([]);
    const [error, setError] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async () => {
        setError(false);
        try {
            const { data } = await getKeys(
                currentPage * itemsPerPage + 1,
                itemsPerPage,
                searchTerm
            );

            const formattedData = data?.external_keys?.map((key) => ({
                ...key,
                id: key.key_id
            }));
            setKeysData(formattedData);
            setPageCount(Math.ceil(data.total / itemsPerPage));
        } catch (err) {
            showErrorToast();
            setError(true);
        }
    };

    const handleEditKey = async (keyToEditId, keyData) => {
        try {
            await updateKey(keyToEditId, keyData);
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            throw error;
        }
    };

    const handleCreateKey = async (keyData) => {
        try {
            await createKey(keyData);
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            throw error;
        }
    }

    const handleDeleteKey = async (keyToDeleteId) => {
        try {
            await deleteKey(keyToDeleteId);
            showSuccessToast();
            fetchData();
        } catch (error) {
            showErrorToast();
        }
    }

    useEffect(() => {
        if (doInitialFetch) {
            fetchData();
        }
    }, [currentPage]);

    return { keysData, error, pageCount, searchTerm, setSearchTerm, fetchData, handleCreateKey, handleEditKey, handleDeleteKey };
};

export default useKeys;
