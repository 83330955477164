import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { publisherTabColumns } from '../columns';
import { getAmbientScreeningPublishers, showErrorToast } from "../../../helpers";

const PublisherTab = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getAmbientScreeningPublishers();
                setData(data?.statistics);
            } catch (error) {
                showErrorToast();
            }
        }

        fetchData();
    }, [])

    return (
        <DataTable
            columns={publisherTabColumns}
            rows={data}
        />
    )
};

export default PublisherTab;