import { useEffect, useState } from "react";
import { getOnDemandScreeningActionStats } from "../../../helpers";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { timeRangeOptions, TimeRangeSelect } from "../../../components";
import DataTable from "../../../components/table/DataTable";
import { signalsColumns } from "../columns";

const SignalsTab = () => {
  const [timeRangeOption, setTimeRangeOption] = useState(
    timeRangeOptions.last_7_days.value
  );
  const [tableData, setTableData] = useState([]);
  const [cardData, setCardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getOnDemandScreeningActionStats(timeRangeOption);
        setTableData(data?.alertsPerSignal ?? []);
        setCardData({
          completedRequestsCount: data?.completedRequestsCount,
          failedRequestsCount: data?.failedRequestsCount,
          submittedRequestsCount: data?.submittedRequestsCount,
          reports: data?.reports,
          uploads: data?.uploads,
        });
      } catch {
      }
    };
    fetchData();
  }, [timeRangeOption]);

  const mappedTableData = tableData?.map((item) => ({
    ...item,
    name: item?.tool + " - " + item?.signal,
  }));

  return (
    <>
      <div className="row my-2 justify-content-end">
        <MDBCol lg={3} md={6} sm={6}>
          <TimeRangeSelect setTimeRangeOption={setTimeRangeOption} />
        </MDBCol>
      </div>
      <MDBCard className="mb-3">
        <MDBCardHeader>
          <MDBCardTitle className="text-center mt-2">Statistics</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBRow>
            <MDBCol>
              <MDBCardText>
                <strong>
                  Submitted requests count: {cardData?.submittedRequestsCount}
                </strong>
              </MDBCardText>
            </MDBCol>
            <MDBCol>
              <MDBCardText>
                <strong>Uploads count: {cardData?.uploads}</strong>
              </MDBCardText>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBCardText>
                <strong>
                  Completed requests count: {cardData?.completedRequestsCount}
                </strong>
              </MDBCardText>
            </MDBCol>
            <MDBCol>
              <MDBCardText>
                <strong>Reports count: {cardData?.reports}</strong>
              </MDBCardText>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBCardText>
                <strong>
                  Failed requests count: {cardData?.failedRequestsCount}
                </strong>
              </MDBCardText>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <DataTable columns={signalsColumns} rows={mappedTableData} />
    </>
  );
};

export default SignalsTab;
