import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPublisher, updatePublisher } from "../../../helpers/publishers";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInputGroup, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import { showErrorToast, showSuccessToast } from "../../../helpers";

const PublisherDetailsTab = () => {
  let { id } = useParams();

  const [publisher, setPublisher] = useState();

  const fetchPublisher = async () => {
    try {
      const response = await getPublisher(id);
      setPublisher(response.data)
    } catch (error) {
      showErrorToast();
    }
  }

  useEffect(() => {
    fetchPublisher();
  }, [])

  const handleSubmit = async () => {
    try {
      await updatePublisher(publisher.publisher_id, {
        name: publisher.name
      })
      showSuccessToast();
      await fetchPublisher();
    } catch (error) {
      showErrorToast();
    }
  }

  return (
    <MDBCard>
      {publisher && (
        <MDBCardBody>
          <MDBTypography className="d-flex justify-content-center" variant="h5">Publisher -<b className="px-2">{publisher?.name}</b></MDBTypography>
          <MDBRow className="mt-4 d-flex ">
            <MDBCol md={4}>
              <MDBRow>
                <label className="grey-text">Publisher name</label>
                <MDBInputGroup className="p-1">

                  <input
                    className="form-control"
                    placeholder="Search by publisher name..."
                    type="text"
                    onChange={e => setPublisher({ ...publisher, name: e.target.value })}
                    value={publisher.name}
                  />
                  <MDBBtn onClick={handleSubmit}>Submit</MDBBtn>

                </MDBInputGroup>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          </MDBRow>
        </MDBCardBody>
      )}
    </MDBCard>
  )
};

export default PublisherDetailsTab;
