import React, { useState } from "react";
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import ExportTab from "./tabs/ExportTab";
import RecentActivityTab from "./tabs/RecentActivityTab";
import RequestsTab from "./tabs/RequestsTab";

const tabs = {
  export: "export",
  recentActivity: "recentActivity",
  requests: 'requests'
};

const ApiAccess = () => {
  const [currentTab, setCurrentTab] = useState(tabs.requests);

  return (
    <>
      <MDBRow>
        <MDBCol md="12" lg="7">
          <MDBTabs className="mb-3">
            <MDBTabsItem onClick={() => setCurrentTab(tabs.requests)}>
              <MDBTabsLink
                active={currentTab === tabs.requests}
              >
                Requests
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.export)}>
              <MDBTabsLink
                active={currentTab === tabs.export}
              >
                Export
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.recentActivity)}>
              <MDBTabsLink
                active={currentTab === tabs.recentActivity}
              >
                Recent activity
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
      </MDBRow>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.export}>
          <ExportTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.recentActivity}>
          <RecentActivityTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.requests}>
          <RequestsTab />
        </MDBTabsPane>
      </MDBTabsContent >
    </>
  );
};

export default ApiAccess;
