import React, { useEffect, useState } from "react";
import { getDupSubStatisticsPerWeek } from "../../../helpers";
import { BarChart } from "../../../components";

const ChartTab = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getDupSubStatisticsPerWeek();
        setData(data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  const keys = Array.from(new Set(data.flatMap(Object.keys))).filter(
    (key) => key !== "week"
  );

  return (
    <BarChart keys={keys} data={data} />
  );
};

export default ChartTab;
