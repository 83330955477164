import { useEffect, useState } from "react";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import DataTable from "../../components/table/DataTable";
import {
  convertISOToReadableDate,
  getCognitoUsers,
  showErrorToast,
} from "../../helpers";
import { cognitoUsersColumns } from "./columns";
import { exportToExcel } from "./export";

const CognitoUsers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getCognitoUsers();
        const mappedData = data?.users?.map((x) => {
          return {
            ...x,
            lastLoginDate: convertISOToReadableDate(x.lastLoginDate),
            lastUpdateDate: convertISOToReadableDate(x.lastUpdateDate),
          };
        });
        setData(mappedData);
      } catch (error) {
        showErrorToast();
      }
    };

    fetchData();
  }, []);

  const handleExport = () => {
    exportToExcel(data, "CognitoUsers");
  };

  return (
    <MDBContainer fluid>
      <div className="d-flex justify-content-end mb-3">
        <MDBBtn
          color="primary"
          className="px-4"
          onClick={handleExport}
        >
          Export
        </MDBBtn>
      </div>
      <DataTable
        columns={cognitoUsersColumns}
        rows={data}
        enableSorting
        defaultSortDirection="dsc"
        defaultSortField="lastLoginDate"
      />
    </MDBContainer>
  );
};

export default CognitoUsers;
