import {
    MDBBtn,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { ModalWrapper } from './ModalWrapper';

const FormModalWrapper = ({
    isOpen,
    onClose,
    title,
    onSubmit,
    submitButtonTitle = 'Submit',
    children,
}) => {
    const handleCloseModal = () => {
        onClose();
    };

    return (
        <ModalWrapper
            title={title}
            isOpen={isOpen}
            onSubmit={onSubmit}
            onClose={handleCloseModal}>
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    await onSubmit();
                }}
            >
                {children}
                <MDBModalFooter className="justify-content-center">
                    <MDBBtn type="submit" color="success">
                        {submitButtonTitle}
                    </MDBBtn>
                </MDBModalFooter>
            </form>
        </ModalWrapper>

    );
};

export { FormModalWrapper };
