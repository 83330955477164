import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { last7DaysTabColumns } from "../columns";
import {
  getOnDemandScreeningSubmitted,
  showErrorToast,
} from "../../../helpers";
import { MDBCol } from "mdb-react-ui-kit";
import { TimeRangeSelect, timeRangeOptions } from "../../../components";

const RequestsTab = () => {
  const [data, setData] = useState([]);
  const [timeRangeOption, setTimeRangeOption] = useState(
    timeRangeOptions.last_7_days.value
  );

  const mapResponseData = (response) => {
    const output = [];

    response?.forEach((item) => {
      output.push({
        publisher: item.publisher,
        publisherSubmitted: item.totalsubmitted,
        user: "",
        submitted: "",
      });

      item?.users?.forEach((user) => {
        output.push({
          publisher: "",
          publisherSubmitted: "",
          user: user.user,
          userSubmitted: user.submitted,
        });
      });
    });

    return output;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getOnDemandScreeningSubmitted(timeRangeOption);
        const mappedData = mapResponseData(data);
        setData(mappedData);
      } catch (error) {
        showErrorToast();
      }
    };

    fetchData();
  }, [timeRangeOption]);

  return (
    <>
      <div className="row my-2 justify-content-end">
        <MDBCol lg={3} md={6} sm={6}>
          <TimeRangeSelect setTimeRangeOption={setTimeRangeOption} />
        </MDBCol>
      </div>
      <DataTable columns={last7DaysTabColumns} rows={data} />
    </>
  );
};

export default RequestsTab;
