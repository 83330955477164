import { axiosInstance } from "./axiosSetup";
import { API_PATHS } from './apiPaths';

export const getUsers = (startrow = 1, maxrows = 8, search = '') => {
    return axiosInstance.get(API_PATHS.GET_USERS(startrow, maxrows, search));
};

export const getUser = (id) => {
    return axiosInstance.get(API_PATHS.GET_USER(id));
};

export const updateUser = (id, data) => {
    return axiosInstance.patch(API_PATHS.UPDATE_USER(id), data);
};

export const deleteUser = (id) => {
    return axiosInstance.delete(API_PATHS.DELETE_USER(id));
};

export const createUser = (user) => {
    return axiosInstance.post(API_PATHS.CREATE_USER, user);
};
