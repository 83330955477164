import { useCallback, useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { recentActivityColumns } from '../columns';
import { convertISOToReadableDate, getDupSubRecent, showErrorToast } from "../../../helpers";
import { MDBBtn } from "mdb-react-ui-kit";

const RecentActivityTab = () => {
    const [data, setData] = useState([]);
    const [lastEventTimestamp, setLastEventTimestamp] = useState('');

    const [hasMoreData, setHasMoreData] = useState(true);

    const initialLoadCount = 100;

    useEffect(() => {
        fetchData(initialLoadCount);
    }, []);

    const fetchData = async (limit, lastTimestamp = '') => {
        try {
            const { data: fetchedData } = await getDupSubRecent(limit, lastTimestamp);
            const recentActivityRows = fetchedData?.lastEvents
                ? fetchedData?.lastEvents.map(item => ({
                    ...item,
                    timestamp: convertISOToReadableDate(item?.eventTimestamp),
                }))
                : [];
            setData(prevData => lastTimestamp === '' ? recentActivityRows : [...prevData, ...recentActivityRows]);

            if (recentActivityRows.length > 0) {
                setLastEventTimestamp(recentActivityRows[recentActivityRows.length - 1].eventTimestamp);
            }

            if (recentActivityRows.length < limit) {
                setHasMoreData(false);
            }
        } catch (error) {
            showErrorToast();
        }
    };

    const loadMoreData = useCallback(async () => {
        await fetchData(initialLoadCount, lastEventTimestamp);
    }, [lastEventTimestamp]);

    return (
        <>
            <DataTable
                columns={recentActivityColumns}
                rows={data}
            />
            {hasMoreData && (
                <div className="d-flex justify-content-center mt-3">
                    <MDBBtn onClick={loadMoreData}>
                        Load more data
                    </MDBBtn>
                </div>
            )}
        </>
    );
};

export default RecentActivityTab;