import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTooltip } from "mdb-react-ui-kit";
import React, { useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import DataTable from "../../components/table/DataTable";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import CreatePublisherModal from "./components/CreatePublisherModal";
import SearchInput from "../../components/SearchInput/SearchInput";
import usePublishers from "./hooks/usePublishers";
import EditPublisherModal from "./components/EditPublisherModal";

export const columns = [
  {
    label: "ID",
    field: "publisher_id",
    width: 100,
  },
  {
    label: "Publisher name",
    field: "name",
  },
  {
    label: "",
    field: "editAction",
    width: 20,
  },
  {
    label: "",
    field: "detailedViewAction",
    width: 20,
  },
];

const Publishers = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isNewPublisherModalOpen, setIsNewPublisherModalOpen] = useState(false);
  const [publisherToEditId, setPublisherToEditId] = useState(null);

  const {
    publishersData,
    error,
    pageCount,
    searchTerm,
    setSearchTerm,
    fetchData,
  } = usePublishers(currentPage);

  const handleSearchFetch = async () => {
    if (currentPage === 0) {
      fetchData();
    } else {
      setCurrentPage(0);
    }
  };

  const handleRefetch = async () => {
    setCurrentPage(0);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleCreatePublisherClick = () => {
    setIsNewPublisherModalOpen(true);
  };

  const publishersDataWithActions = publishersData.map((publisher) => ({
    ...publisher,
    editAction: (
      <div key={publisher.id} onClick={(e) => e.stopPropagation()}>
        <MDBTooltip tag={"div"} title="Edit publisher" placement="top">
          <MDBBtn
            color="secondary"
            floating
            onClick={() => setPublisherToEditId(publisher.publisher_id)}
          >
            <MDBIcon fas icon="edit" />
          </MDBBtn>
        </MDBTooltip>
      </div>
    ),
  }));

  return (
    <>
      <CreatePublisherModal
        isOpen={isNewPublisherModalOpen}
        setIsOpen={setIsNewPublisherModalOpen}
        onSuccessfulSubmit={handleRefetch}
      />
      <EditPublisherModal
        itemId={publisherToEditId}
        onClose={() => setPublisherToEditId(null)}
        onSuccessSubmit={fetchData}
      />
      <MDBRow className="mb-2">
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput
            placeholder="Search by publisher name..."
            value={searchTerm}
            setValue={setSearchTerm}
            onSubmit={handleSearchFetch}
          />
        </MDBCol>
        <MDBCol className="text-end">
          <MDBBtn
            color="primary"
            size="md"
            onClick={handleCreatePublisherClick}
          >
            <MDBIcon icon="plus-circle" className="me-2" />
            New publisher
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && publishersData && (
        <>
          <DataTable rows={publishersDataWithActions} columns={columns} />
          <MDBRow>
            <MDBCol className="mt-2">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            </MDBCol>
          </MDBRow>
        </>
      )}
    </>
  );
};

export default Publishers;
