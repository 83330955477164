import React, { useMemo, useState } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTooltip,
} from "mdb-react-ui-kit";
import DataTable from "../../components/table/DataTable";
import Pagination from "../../components/Pagination/Pagination";
import KeyEditModal from "./components/KeyEditModal";
import KeyCreateModal from "./components/KeyCreateModal";
import Dialog from "../../components/Dialog/Dialog";
import SearchInput from "../../components/SearchInput/SearchInput";
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import useKeys from "./hooks/useKeys";

export const columns = [
  {
    label: "ID",
    field: "id",
    width: 250,
  },
  {
    label: "Key name",
    field: "key_name",
  },
  {
    label: "Key path",
    field: "key_path",
  },
  {
    labeL: "",
    field: "editAction",
  },
  {
    labeL: "",
    field: "deleteAction",
  },
];

const Keys = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const {
    keysData,
    error,
    pageCount,
    searchTerm,
    setSearchTerm,
    fetchData,
    handleDeleteKey
  } = useKeys({ currentPage: currentPage });

  const [isNewKeyModalOpen, setIsNewKeyModalOpen] = useState(false);
  const [keyToEditId, setKeyToEditId] = useState(null);
  const [keyToDeleteId, setKeyToDeleteId] = useState(null);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleDeleteKeySubmit = async () => {
    await handleDeleteKey(keyToDeleteId);
    setKeyToDeleteId(null);
  }

  const handleSearchFetch = async () => {
    if (currentPage === 0) {
      fetchData();
    }
    else {
      setCurrentPage(0)
    }
  }

  const keysDataWithActions = useMemo(() => keysData?.map(key => ({
    ...key,
    editAction:
      <div key={key.id} onClick={(e) => e.stopPropagation()}>
        <MDBTooltip tag={"div"} title="Edit Key" placement="top">
          <MDBBtn
            color="secondary"
            floating
            onClick={() => setKeyToEditId(key.id)}
          >
            <MDBIcon fas icon="edit" />
          </MDBBtn>
        </MDBTooltip>
      </div>,
    deleteAction:
      <div key={key.id} onClick={e => e.stopPropagation()} >
        <MDBTooltip tag={"div"} title="Remove Key" placement="top">
          <MDBBtn color='danger' floating onClick={() => setKeyToDeleteId(key.id)}>
            <MDBIcon far icon='trash-alt' />
          </MDBBtn>
        </MDBTooltip>
      </div>
  })), [keysData]);

  return (
    <>
      <KeyEditModal
        itemId={keyToEditId}
        onClose={() => setKeyToEditId(null)}
        onSuccessSubmit={fetchData}
      />
      <KeyCreateModal
        isOpen={isNewKeyModalOpen}
        onClose={() => setIsNewKeyModalOpen(false)}
        onSuccessSubmit={fetchData}
      />
      <Dialog
        onConfirm={handleDeleteKeySubmit}
        isOpen={!!keyToDeleteId}
        setIsOpen={setKeyToDeleteId}
        dialogText={"Are you sure you want to delete this key?"}
      />
      <MDBRow className="mb-2">
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput placeholder={'Search by key name...'} value={searchTerm} setValue={setSearchTerm} onSubmit={handleSearchFetch} />
        </MDBCol>
        <MDBCol className="text-end">
          <MDBBtn
            color="primary"
            size="md"
            onClick={() => setIsNewKeyModalOpen(true)}
          >
            <MDBIcon icon="plus-circle" className="me-2" />
            New key
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && keysData && (
        <>
          <DataTable rows={keysDataWithActions} columns={columns} />
          <MDBRow>
            <MDBCol className="mt-2">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            </MDBCol>
          </MDBRow>
        </>
      )}
    </>
  );
};

export default Keys;
