import { axiosInstance } from './axiosSetup';
import { API_PATHS } from './apiPaths';

export const getWatchlist = (type, startrow, maxrows) => {
  return axiosInstance.get(API_PATHS.WATCHLIST(type, startrow, maxrows));
};

export const exportWatchlist = async (type) => {
  return axiosInstance.get(API_PATHS.EXPORT_WATCHLIST(type));
};

export const importFilePresign = async (file) => {
  return axiosInstance.get(API_PATHS.IMPORT_FILE_PRESIGN, file);
};

export const importFilePreview = async (fileName, type) => {
  return axiosInstance.get(API_PATHS.IMPORT_FILE_PREVIEW(fileName, type));
};

export const importFileConfirm = async (fileName, type) => {
  return axiosInstance.get(API_PATHS.IMPORT_FILE_CONFIRM(fileName, type));
};

export const getCheckerList = async (startrow, maxrows, search) => {
  return axiosInstance.get(API_PATHS.GET_CHECKER_LIST(startrow, maxrows, search));
};

export const duplicateWatchlistRecord = async (id) => {
  return axiosInstance.post(API_PATHS.DUPLICATE_WATCHLIST_RECORD(id));
};

export const extractManuscriptForSaleManual = async (content) => {
  return axiosInstance.post(`/watchlist/extract-manuscript-for-sale/manual`, { content });
};

export const uploadManuscriptForSaleManual = async (object) => {
  return axiosInstance.post(`/watchlist/upload-manuscript-for-sale/manual`, object);
};
