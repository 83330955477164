import * as XLSX from 'xlsx';

export const exportToExcel = (data, columns, fileName) => {
  const fieldToLabelMap = {};
  columns.forEach(column => {
    fieldToLabelMap[column.field] = column.label;
  });

  const flattenedData = [];

  data.forEach(publisher => {
    publisher.profiles.forEach(profile => {
      const rowData = {};

      columns.forEach(column => {
        rowData[column.label] = undefined;
      });

      rowData["Publisher"] = publisher.name;
      rowData["Profile"] = profile.profileName;

      if (profile.components) {
        Object.keys(profile.components).forEach(componentKey => {
          if (typeof profile.components[componentKey] !== 'object' || profile.components[componentKey] === null) {
            const label = fieldToLabelMap[componentKey];
            if (label) {
              rowData[label] = profile.components[componentKey];
            }
          }
        });

        Object.keys(profile.components).forEach(componentKey => {
          if (typeof profile.components[componentKey] === 'object' && profile.components[componentKey] !== null) {
            const nestedObj = profile.components[componentKey];

            Object.keys(nestedObj).forEach(nestedKey => {
              const fieldName = `${componentKey}.${nestedKey}`;
              const label = fieldToLabelMap[fieldName];
              if (label) {
                rowData[label] = nestedObj[nestedKey];
              }
            });
          }
        });
      }

      flattenedData.push(rowData);
    });
  });

  const headers = columns.map(column => column.label);

  const worksheet = XLSX.utils.json_to_sheet(flattenedData, { header: headers });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, fileName);
};
