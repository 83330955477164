import React, { useEffect, useState } from "react";
import { getJournalsList } from "../../../helpers/journals";
import DataTable from "../../../components/table/DataTable";
import { journalsListColumns } from "../columns";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { MDBBtn } from "mdb-react-ui-kit";
import { exportToExcel } from "../export";

const JournalListTab = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getJournalsList();
        const mappedData = transformData(response);
        setData(mappedData);
      } catch (error) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  const transformData = (response) => {
    const output = [];

    response?.forEach((publisher) => {
      output.push({
        publisher: publisher.name ?? "",
        journalsCount: publisher.journalsCount ?? "",
      });

      publisher?.journals?.forEach((journal) => {
        output.push({
          journalName: journal.journalName ?? "",
          subject: journal?.subject ?? "No subject",
          publisherJournalId: journal?.publisherJournalId ?? "",
        });
      });
    });

    return output;
  };

  const handleExport = () => {
    exportToExcel(data, "JournalsList.xlsx");
  };

  const showErrorMessage = error;
  const showData = !showErrorMessage && data?.length > 0;

  return (
    <>
      {showErrorMessage && <ErrorMessage />}
      {showData && (
        <>
          <div className="d-flex justify-content-end mb-3">
            <MDBBtn
              color="primary"
              className="px-4"
              onClick={handleExport}
            >
              Export
            </MDBBtn>
          </div>

          <DataTable columns={journalsListColumns} rows={data} />
        </>
      )}
    </>
  );
};

export default JournalListTab;
