export const recentActivityColumns = [
    {
        label: 'Timestamp',
        field: 'timestamp',
        width: 160,
    },
    {
        label: 'Publisher name',
        field: 'publisherName',
        width: 150,
    },
    {
        label: 'Application',
        field: 'application',
    },
    {
        label: 'Service type',
        field: 'serviceType',
    },
    {
        label: 'Manuscript',
        field: 'manuscript',
    },
    {
        label: 'Parameters',
        field: 'parameters',
    },
    {
        label: 'Status',
        field: 'statusCode',
    }
];

export const requestsColumns = [
    {
        label: 'Publisher',
        field: 'publisher',
    },
    {
        label: 'Pubpeer',
        field: 'pubpeer',
    },
    {
        label: 'Clearskies',
        field: 'clearskies',
    },
    {
        label: 'Cactus total',
        field: 'cactusTotal',
    },
    {
        label: 'Cactus billable',
        field: 'cactusBillable',
    }
]

export const requestsExtendedViewColumns = [
    ...requestsColumns,
    {
        label: 'Reference Citation (Results + Enabled)',
        field: 'reference_cited',
    },
    {
        label: 'Author Location (Results + Enabled)',
        field: 'author_location',
    },
    {
        label: 'Age of Reference (Results + Enabled)',
        field: 'age_of_reference',
    },
    {
        label: 'Unrelated References (Results + Enabled)',
        field: 'unrelated_reference',
    },
    {
        label: 'Self Citation (Results + Enabled)',
        field: 'self_citation_reference',
    },
    {
        label: 'Citation Cartel (Results + Enabled)',
        field: 'citation_cartel_reference',
    },
    {
        label: 'Author Affiliation Relevance (Results + Enabled)',
        field: 'author_affiliation_relevance',
    }
]