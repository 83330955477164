import axios from "axios";
import { logOut } from "./auth";
import { token as TOKEN, apiBaseUrl } from "../constants";
import { getGlobalLoader } from "stm-frontend-library";

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
});

// Request interceptor: Show loader before the request starts
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN);
  config.headers.Authorization = `Bearer ${token}`;
  config.headers["Content-Type"] = "application/json";

  const { showLoader } = getGlobalLoader();
  showLoader(); // Show global loader
  return config;
});

// Response interceptor: Hide loader after a response
axiosInstance.interceptors.response.use(
  (response) => {
    const { hideLoader } = getGlobalLoader();
    hideLoader(); // Hide global loader on success
    return response;
  },
  (error) => {
    const { hideLoader } = getGlobalLoader();
    hideLoader(); // Hide global loader even on error

    if (error?.response?.status === 401) {
      logOut();
    } else {
      console.error(error);
    }
    throw error;
  }
);

export { axiosInstance };
