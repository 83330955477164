import React, { useEffect, useState } from 'react';
import { DataTable } from 'stm-frontend-library'
import { requestsColumns, requestsExtendedViewColumns } from "../columns";
import { MDBBtn, MDBCol, MDBSwitch } from 'mdb-react-ui-kit';
import { timeRangeOptions, TimeRangeSelect } from '../../../components';
import { getApiAccessRequests } from '../../../helpers/monitoring';
import { showErrorToast } from '../../../helpers'
import { exportToExcel } from '../export';

const RequestsTab = () => {
    const [data, setData] = useState([]);
    const [timeRangeOption, setTimeRangeOption] = useState(timeRangeOptions.last_7_days.value);
    const [isViewExtended, setIsViewExtended] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: response } = await getApiAccessRequests(timeRangeOption);
                setData(response);
            } catch (error) {
                showErrorToast();
            }
        }
        fetchData();
    }, [timeRangeOption]);

    const mappedData = data.map(x => {
        return {
            publisher: x.publisher,
            pubpeer: x.counts.PUBPEER,
            clearskies: x.counts.CLEARSKIES,
            cactusTotal: x.counts.CACTUS.total,
            cactusBillable: x.counts.CACTUS.billable,
            reference_cited: x.counts.CACTUS.results.reference_cited + '/' + x.counts.CACTUS.enabled.reference_cited,
            author_location: x.counts.CACTUS.results.author_location + '/' + x.counts.CACTUS.enabled.author_location,
            age_of_reference: x.counts.CACTUS.results.age_of_reference + '/' + x.counts.CACTUS.enabled.age_of_reference,
            unrelated_reference: x.counts.CACTUS.results.unrelated_reference + '/' + x.counts.CACTUS.enabled.unrelated_reference,
            self_citation_reference: x.counts.CACTUS.results.self_citation_reference + '/' + x.counts.CACTUS.enabled.self_citation_reference,
            citation_cartel_reference: x.counts.CACTUS.results.citation_cartel_reference + '/' + x.counts.CACTUS.enabled.citation_cartel_reference,
            author_affiliation_relevance: x.counts.CACTUS.results.author_affiliation_relevance + '/' + x.counts.CACTUS.enabled.author_affiliation_relevance
        }
    })

    const handleExport = () => {
        exportToExcel(mappedData, "API Access Requests");
    };

    return (
        <>
            <div className="d-flex justify-content-end align-items-center gap-3 my-2 flex-wrap">
                <MDBSwitch
                    label="Extended view"
                    value={isViewExtended}
                    onChange={() => setIsViewExtended((prevState) => !prevState)}
                />
                <MDBBtn
                    color="primary"
                    className="px-4"
                    onClick={handleExport}
                >
                    Export
                </MDBBtn>
                <MDBCol lg={2} md={6} sm={6} >
                    <TimeRangeSelect setTimeRangeOption={setTimeRangeOption} />
                </MDBCol>
            </div>

            <DataTable
                columns={isViewExtended ? requestsExtendedViewColumns : requestsColumns}
                rows={mappedData}
            />
        </>
    );
};

export default RequestsTab;
