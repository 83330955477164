export const publisherTabColumns = [
  {
    label: "Publisher",
    field: "publisherName",
    width: 250,
  },
  {
    label: "Total",
    field: "count",
    width: 50,
  },
];

export const last7DaysTabColumns = [
  {
    label: "Publisher",
    field: "publisher",
    width: 100,
  },
  {
    label: "Total Submitted",
    field: "publisherSubmitted",
    width: 50,
  },
  {
    label: "User",
    field: "user",
    width: 50,
  },
  {
    label: "Submitted",
    field: "userSubmitted",
    width: 50,
  },
];

export const monthlyTabColumns = [
  {
    label: "Timestamp",
    field: "date",
    width: 120,
  },
  {
    label: "Submitted",
    field: "submitted",
    width: 50,
  },
];

export const recentActivityColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
    width: 120,
  },
  {
    label: "Publisher",
    field: "publisherName",
  },
  {
    label: "Manuscript",
    field: "persistentId",
  },
  {
    label: "User",
    field: "userName",
  },
];

export const journalsColumns = [
  {
    label: "Publisher",
    field: "publisher",
  },
  {
    label: "# journals",
    field: "journalsCount",
  },
  {
    label: "Journal",
    field: "journalName",
  },
  {
    label: "# submissions",
    field: "submissionsCount",
  },
];

export const signalsColumns = [
  {
    label: "Signal Type",
    field: "signalType",
  },
  {
    label: "# signals",
    field: "screenedCount",
  },
  {
    label: "# hits",
    field: "count",
  }
];
