import { useState, useEffect, useCallback } from 'react';
import { itemsPerPage } from '../../../../constants';
import { getUsersByPublisher, deleteUser, showErrorToast, showSuccessToast } from "../../../../helpers";

export const usePublisherUsers = (publisherId) => {
    const [usersData, setUsersData] = useState(null);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const fetchData = useCallback(async () => {
        setError(false);
        try {
            const { data } = await getUsersByPublisher(publisherId);
            const formattedData = data.rows.map((user) => ({
                ...user,
                id: user.user_id
            }));
            setUsersData(formattedData);
            setPageCount(Math.ceil(data.total / itemsPerPage));
        } catch (err) {
            setError(true);
        }
    }, [publisherId]);

    const handleDeleteUser = async (userId) => {
        try {
            await deleteUser(userId);
            showSuccessToast();
            await fetchData();
        } catch (error) {
            showErrorToast();
        }
    };

    useEffect(() => {
        fetchData();
    }, [publisherId, currentPage, fetchData]);

    return {
        usersData,
        error,
        currentPage,
        setPageCount,
        pageCount,
        setCurrentPage,
        handleDeleteUser,
        fetchData,
    };
};
