import { useEffect, useState } from "react";
import { getAmbientScreeningActionStats } from "../../../helpers";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { timeRangeOptions, TimeRangeSelect } from "../../../components";
import DataTable from "../../../components/table/DataTable";
import { signalsColumns } from "../columns";

const SignalsTab = () => {
  const [tableData, setTableData] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [timeRangeOption, setTimeRangeOption] = useState(
    timeRangeOptions.last_7_days.value
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAmbientScreeningActionStats(timeRangeOption);
        setTableData(data?.screenedManuscriptsToEachTool ?? []);
        setCardData({
          completedRequestsCount: data?.completedRequestsCount,
          submittedRequestsCount: data?.submittedRequestsCount,
          failedRequestsCount: data?.failedRequestsCount,
          resultingInSignal: data?.resultingInSignal,
        });
      } catch (error) {
      }
    };
    fetchData();
  }, [timeRangeOption]);

  return (
    <>
      <div className="row my-2 justify-content-end">
        <MDBCol lg={3} md={6} sm={6}>
          <TimeRangeSelect setTimeRangeOption={setTimeRangeOption} />
        </MDBCol>
      </div>
      <MDBCard className="mb-3">
        <MDBCardHeader>
          <MDBCardTitle className="text-center mt-2">Statistics</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBRow>
            <MDBCol>
              <MDBCardText>
                <strong>
                  Submitted requests count: {cardData?.submittedRequestsCount}
                </strong>
              </MDBCardText>
            </MDBCol>
            <MDBCol>
              <MDBCardText>
                <strong>
                  Failed requests count: {cardData?.failedRequestsCount}
                </strong>
              </MDBCardText>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBCardText>
                <strong>
                  Completed requests count: {cardData?.completedRequestsCount}
                </strong>
              </MDBCardText>
            </MDBCol>
            <MDBCol>
              <MDBCardText>
                <strong>
                  Resulting in signal: {cardData?.resultingInSignal}
                </strong>
              </MDBCardText>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <DataTable columns={signalsColumns} rows={tableData} />
    </>
  );
};

export default SignalsTab;
