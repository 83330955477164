import React, { useEffect } from "react";
import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBInput,
} from "mdb-react-ui-kit";
import Select from "react-select";
import SwitchWrapper from "./SwitchWrapper";
import SwitchGroup from "./SwitchGroup";
import FeatureSection from "./FeatureSection";
import useKeys from "../../keys/hooks/useKeys";

const PublisherProfileForm = ({ values, onChange, errors, disabledSections = {} }) => {
  const { keysData } = useKeys({ itemsPerPage: Number.MAX_SAFE_INTEGER });

  const resetPubPeerAPIKey = () => onChange("service_keys.pubPeerAPIKey", null);
  const resetClearSkiesAPIKey = () =>
    onChange("service_keys.clearSkiesAPIKey", null);

  useEffect(() => {
    const handleReset = () => {
      if (values?.components?.FOCRat === false) {
        resetPubPeerAPIKey();
      }

      if (values?.components?.CSStat === false) {
        resetClearSkiesAPIKey();
      }
    };

    handleReset();
  }, [values?.components?.FOCRat, values?.components?.CSStat]);

  const keyOptions = keysData?.map((key) => ({
    value: key.id,
    label: key.key_name,
  }));

  return (
    <>
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBTypography variant="h5" className="mb-3">
            Profile
          </MDBTypography>
          <MDBInput
            label="Profile name"
            id="profile_name"
            name="profile_name"
            type="text"
            onChange={({ target }) => onChange(target.name, target.value)}
            value={values?.profile_name}
          />
          {errors?.profile_name && (
            <div className="text-danger">{errors.profile_name}</div>
          )}
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h5" className="mb-3">
            Applications
          </MDBTypography>
          <SwitchGroup>
            <SwitchWrapper
              checked={values?.components?.PMChk ?? false}
              label="On-Demand Screening"
              name="components.PMChk"
              onChange={({ target }) => onChange(target?.name, target?.checked)}
            />
            <SwitchWrapper
              checked={values?.components?.AMBScr ?? false}
              label="Ambient Screening"
              name="components.AMBScr"
              onChange={({ target }) => onChange(target?.name, target?.checked)}
            />
            <SwitchWrapper
              checked={values?.components?.WTLst ?? false}
              label="Watchlist"
              name="components.WTLst"
              onChange={({ target }) => onChange(target?.name, target?.checked)}
            />
          </SwitchGroup>
        </MDBCol>
      </MDBRow>
      <MDBTypography variant="h5" className="mb-3">
        Tools & signals
      </MDBTypography>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            PubPeer
          </MDBTypography>
          <SwitchGroup>
            <SwitchWrapper
              label="PubPeer hits"
              checked={values?.components?.FOCRat ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.FOCRat"
            />
            <div>
              <label className="grey-text">PubPeer API Key</label>
              <Select
                key={values?.service_keys?.pubPeerAPIKey}
                name="service_keys.pubPeerAPIKey"
                placeholder="Select key"
                options={keyOptions}
                value={keyOptions.find(
                  (element) => element.value === values?.service_keys?.pubPeerAPIKey
                )}
                isDisabled={!values?.components?.FOCRat}
                onChange={({ value }, { name }) => onChange(name, value)}
              />
              {values?.components?.FOCRat === true &&
                !values?.service_keys?.pubPeerAPIKey ? (
                <div className="text-danger">Field is required</div>
              ) : null}
            </div>
          </SwitchGroup>
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            ClearSkies Papermill Alarm: Public
          </MDBTypography>
          <SwitchGroup className="mb-2">
            <SwitchWrapper
              label="Papermill similarity"
              checked={values?.components?.CSStat ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.CSStat"
            />
            <div>
              <label className="grey-text">ClearSkies API Key</label>
              <Select
                key={values?.service_keys?.clearSkiesAPIKey}
                name="service_keys.clearSkiesAPIKey"
                placeholder="Select key"
                options={keyOptions}
                value={keyOptions.find(
                  (element) =>
                    element.value === values?.service_keys?.clearSkiesAPIKey
                )}
                isDisabled={!values?.components?.CSStat}
                onChange={({ value }, { name }) => onChange(name, value)}
              />
              {values?.components?.CSStat === true &&
                !values?.service_keys?.clearSkiesAPIKey ? (
                <div className="text-danger">Field is required</div>
              ) : null}
            </div>
          </SwitchGroup>
        </MDBCol>
      </MDBRow>
      <FeatureSection
        title="Cactus Integrity Checks"
        messageWhenHidden="Cactus Integrity Checks are not available for this publisher"
        visible={!disabledSections.cactusIntegrityChecks}
      >
        <MDBCol>
          <SwitchGroup>
            <SwitchWrapper
              label="Author affiliation relevance"
              checked={values?.components?.cactusTool?.authorAffiliationRelevance ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.cactusTool.authorAffiliationRelevance"
            />
            <SwitchWrapper
              label="Author geographical distribution"
              checked={values?.components?.cactusTool?.authorGeographicalDistribution ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.cactusTool.authorGeographicalDistribution"
            />
            <SwitchWrapper
              label="Age of reference"
              checked={values?.components?.cactusTool?.ageOfReference ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.cactusTool.ageOfReference"
            />
            <SwitchWrapper
              label="Reference citation"
              checked={values?.components?.cactusTool?.referenceCitation ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.cactusTool.referenceCitation"
            />
          </SwitchGroup>
        </MDBCol>
        <MDBCol>
          <SwitchGroup>
            <SwitchWrapper
              label="Citation cartel"
              checked={values?.components?.cactusTool?.citationCartel ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.cactusTool.citationCartel"
            />
            <SwitchWrapper
              label="Self citation"
              checked={values?.components?.cactusTool?.selfCitation ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.cactusTool.selfCitation"
            />
            <SwitchWrapper
              label="Unrelated references"
              checked={values?.components?.cactusTool?.unrelatedReferences ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.cactusTool.unrelatedReferences"
            />
          </SwitchGroup>
        </MDBCol>
      </FeatureSection>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Reference analysis
          </MDBTypography>
          <SwitchGroup>
            <SwitchWrapper
              label="Retraction Watch"
              checked={values?.components?.RWtch ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.RWtch"
            />
            <SwitchWrapper
              label="DOI analysis"
              checked={values?.components?.CRRF ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.CRRF"
            />
          </SwitchGroup>
        </MDBCol>
        <MDBCol className="">
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Text analysis
          </MDBTypography>
          <SwitchGroup>
            <SwitchWrapper
              label="Tortured phrases"
              checked={values?.components?.TPHrs ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.TPHrs"
            />
            <SwitchWrapper
              label="GenAI response"
              checked={values?.components?.genAIResponse ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.genAIResponse"
            />
            <SwitchWrapper
              label="Unnatural text"
              checked={values?.components?.MLDet ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.MLDet"
            />
          </SwitchGroup>
        </MDBCol>
      </MDBRow >
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Metadata analysis
          </MDBTypography>
          <SwitchWrapper
            label="Word file metadata"
            checked={values?.components?.wordFileProperties ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.wordFileProperties"
          />
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Duplicate submissions
          </MDBTypography>
          <SwitchWrapper
            checked={values?.components?.OPScr ?? false}
            label="Metadata screening"
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.OPScr"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBTypography variant="h6" className="mb-3 fw-bold">
          Watchlist
        </MDBTypography>
        <MDBCol>
          <SwitchGroup>
            <SwitchWrapper
              label="Fake affiliation names"
              checked={values?.components?.SSPan ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSPan"
            />
            <SwitchWrapper
              label="Fake names or e-mail addresses"
              checked={values?.components?.SSPidd ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSPidd"
            />
            <SwitchWrapper
              label="Fake e-mail domains"
              checked={values?.components?.SSPfd ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSPfd"
            />
            <SwitchWrapper
              label="Disposable e-mail domains"
              checked={values?.components?.SSPdd ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSPdd"
            />
          </SwitchGroup>
        </MDBCol>
        <MDBCol>
          <SwitchGroup>
            <SwitchWrapper
              label="Bad actors"
              checked={values?.components?.SSPba ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSPba"
            />
            <SwitchWrapper
              label="Suspect articles"
              checked={values?.components?.SSPsa ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSPsa"
            />
            <SwitchWrapper
              label="Manuscripts offered for sale"
              checked={values?.components?.SSPmfs ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSPmfs"
            />
            <SwitchWrapper
              label="Metadata suspects"
              checked={values?.components?.SSMeta ?? false}
              onChange={({ target }) => onChange(target?.name, target?.checked)}
              name="components.SSMeta"
            />
          </SwitchGroup>
        </MDBCol>
      </MDBRow >
    </>
  );
};

export default PublisherProfileForm;
