import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { getAmbientScreeningJournals, showErrorToast } from "../../../helpers";
import { journalsColumns } from "../columns";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { exportToExcel } from "../export";

const JournalsTab = () => {
  const [tableData, setTableData] = useState([]);
  const [cardData, setCardData] = useState(null);

  const mapResponseData = (response) => {
    const output = [];

    response?.journals?.forEach((item) => {
      output.push({
        publisher: item.name,
        journalsCount: item.count,
      });

      item?.journals?.forEach((journal) => {
        output.push({
          journalName: journal.journalTitle,
          submissionsCount: journal.submissionsCount,
        });
      });
    });

    return output;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAmbientScreeningJournals();
        const mappedData = mapResponseData(data);
        setTableData(mappedData);
        setCardData({
          totalJournals: data?.totalJournals,
          totalPublishers: data?.totalPublishers,
        });
      } catch (error) {
        showErrorToast();
      }
    };

    fetchData();
  }, []);

  const handleExport = () => {
    exportToExcel(tableData, "Journals");
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <MDBBtn
          color="primary"
          className="px-4"
          onClick={handleExport}
        >
          Export
        </MDBBtn>
      </div>
      <MDBCard className="mb-3">
        <MDBCardHeader>
          <MDBCardTitle className="text-center mt-2">Statistics</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBCardText>
            <strong>Total # publishers: {cardData?.totalPublishers}</strong>
          </MDBCardText>
          <MDBCardText>
            <strong>Total # journals: {cardData?.totalJournals}</strong>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <DataTable columns={journalsColumns} rows={tableData} />
    </>
  );
};

export default JournalsTab;
