import { axiosInstance } from "./axiosSetup";
import { API_PATHS } from './apiPaths';

export const getKeys = (startrow = 1, maxrows = Number.MAX_SAFE_INTEGER, search = '') => {
    return axiosInstance.get(API_PATHS.GET_KEYS(startrow, maxrows, search));
};

export const getKey = (id) => {
    return axiosInstance.get(API_PATHS.GET_KEY(id));
};

export const createKey = (data) => {
    return axiosInstance.post(API_PATHS.CREATE_KEY, data);
};

export const updateKey = (id, data) => {
    return axiosInstance.patch(API_PATHS.UPDATE_KEY(id), data);
};

export const deleteKey = (id) => {
    return axiosInstance.delete(API_PATHS.DELETE_KEY(id));
};
