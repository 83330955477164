import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { monthlyTabColumns } from "../columns";
import {
  getAmbientScreeningMonthly,
  getOnAmbientScreeningStatisticsPerWeek,
  showErrorToast,
} from "../../../helpers";
import { BarChart } from "../../../components";

const MonthlyTab = () => {
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [monthlyData, weeklyStats] = await Promise.all([
          getAmbientScreeningMonthly(),
          getOnAmbientScreeningStatisticsPerWeek(),
        ]);

        setData(monthlyData.data);
        setChartData(weeklyStats.data);
      } catch (error) {
        showErrorToast();
      }
    };

    fetchData();
  }, []);

  const keys = Array.from(new Set(chartData.flatMap(Object.keys))).filter(
    (key) => key !== "week"
  );

  return (
    <>
      <BarChart keys={keys} data={chartData} />
      <DataTable columns={monthlyTabColumns} rows={data} />
    </>
  );
};

export default MonthlyTab;
