import React, { useEffect, useState } from "react";
import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import { getPublisherProfilesMonitoring, showErrorToast } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { columns } from "./columns";
import { exportToExcel } from "./export";
import { DataTable } from 'stm-frontend-library'
import styled from "styled-components";

const MonitorProfiles = () => {
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const navigate = useNavigate();

  const getColumnLabelByKey = (key) => {
    const column = columns.find((col) => col.field === key);
    return column ? column.label : key;
  };

  const prepareComponents = (components, counts) => {
    const prepared = {};
    if (components) {
      for (const [key, value] of Object.entries(components)) {
        if (key === 'cactusTool' && typeof value === 'object') {
          columns
            .filter(col => col.field?.startsWith('cactusTool.'))
            .forEach(col => {
              const subField = col.field.split('.')[1];
              const fieldValue = value[subField];

              if (fieldValue !== undefined) {
                const tooltipTitle = getColumnLabelByKey(col.field);
                if (fieldValue) {
                  counts[col.field] = (counts[col.field] || 0) + 1;
                }
                prepared[col.field] = fieldValue ? (
                  <MDBTooltip tag="a" wrapperProps={{ href: "#" }} title={tooltipTitle}>
                    <MDBIcon fas icon="check" className="text-success" />
                  </MDBTooltip>
                ) : (
                  <MDBTooltip tag="a" wrapperProps={{ href: "#" }} title={tooltipTitle}>
                    <MDBIcon fas icon="times" className="text-danger" />
                  </MDBTooltip>
                );
              } else {
                prepared[col.field] = null;
              }
            });
        } else if (key !== 'cactusTool') {
          const tooltipTitle = getColumnLabelByKey(key);
          if (value) {
            counts[key] = (counts[key] || 0) + 1;
          }
          prepared[key] = value ? (
            <MDBTooltip tag="a" wrapperProps={{ href: "#" }} title={tooltipTitle}>
              <MDBIcon fas icon="check" className="text-success" />
            </MDBTooltip>
          ) : (
            <MDBTooltip tag="a" wrapperProps={{ href: "#" }} title={tooltipTitle}>
              <MDBIcon fas icon="times" className="text-danger" />
            </MDBTooltip>
          );
        }
      }
    }
    return prepared;
  };

  const mapResponseData = (responseArray) => {
    const output = [];
    const counts = {};

    responseArray?.forEach((response) => {
      output.push({
        publisherName: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/publisher-management/${response.id}`);
            }}
          >
            {response?.name}
          </a>
        ),
      });
      response?.profiles?.forEach((profile) => {
        const componentsWithIcons = prepareComponents(
          profile?.components,
          counts
        );
        output.push({
          profileName: (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/publisher-management/${response.id}`);
              }}
            >
              {profile?.profileName}
            </a>
          ),
          ...componentsWithIcons,
        });
      });
    });

    const totalRow = { publisherName: "Count" };
    columns.forEach((column) => {
      if (column.field !== "publisherName" &&
        column.field !== "profileName" &&
        column.field !== "dummy") {
        totalRow[column.field] = counts[column.field] || 0;
      }
    });

    output.push(totalRow);
    return output;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getPublisherProfilesMonitoring();
        setExportData(data);
        const mappedData = mapResponseData(data);
        setData(mappedData);
      } catch {
        showErrorToast();
      }
    };
    fetchData();
  }, []);

  const handleExport = () => {
    exportToExcel(exportData, columns, "ProfilesMonitoring.xlsx");
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <MDBBtn
          color="primary"
          className="px-4"
          onClick={handleExport}
        >
          Export
        </MDBBtn>
      </div>

      <TableScaleWrapper>
        <DataTable
          columns={columns}
          rows={data}
          verticalHeaders
          enableSelectColumn
        />
      </TableScaleWrapper>
    </ >
  );
};

const TableScaleWrapper = styled.div`
  zoom: 0.85;
  width: fit-content;
`;

export default MonitorProfiles;
