import React, { useState } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTooltip,
} from "mdb-react-ui-kit";
import DataTable from "../../components/table/DataTable";
import Pagination from "../../components/Pagination/Pagination";
import UserEditModal from "./components/UserEditModal";
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import SearchInput from "../../components/SearchInput/SearchInput";
import useUsers from "./hooks/useUsers";

export const columns = [
  {
    label: "Username",
    field: "username",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: 'Publisher name',
    field: 'publisherName'
  },
  {
    label: 'Administrator',
    field: 'administratorsGroup'
  },
  {
    labeL: "",
    field: "editAction",
  },
];

const Users = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [userToEditId, setUserToEditId] = useState(null);

  const { usersData, error, pageCount, searchTerm, setSearchTerm, fetchData } = useUsers(currentPage);

  const handleSearchFetch = async () => {
    if (currentPage === 0) {
      fetchData();
    }
    else {
      setCurrentPage(0)
    }
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const usersDataWithActions = usersData?.map(user => ({
    ...user,
    editAction: (
      <MDBTooltip tag="div" title="Edit User" placement="top">
        <MDBBtn
          color="secondary"
          floating
          onClick={() => setUserToEditId(user.id)}
        >
          <MDBIcon fas icon="edit" />
        </MDBBtn>
      </MDBTooltip>
    )
  }));

  return (
    <>
      <UserEditModal
        itemId={userToEditId}
        isOpen={!!userToEditId}
        onClose={() => setUserToEditId(null)}
        onSuccessSubmit={fetchData}
      />
      <MDBRow className="mb-2">
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput placeholder="Search by username..." value={searchTerm} setValue={setSearchTerm} onSubmit={handleSearchFetch} />
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && usersData && (
        <>
          <DataTable rows={usersDataWithActions} columns={columns} />
          <MDBRow>
            <MDBCol className="mt-2">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            </MDBCol>
          </MDBRow>
        </>
      )}
    </ >
  );
};

export default Users;
