import { MDBRow, MDBTypography } from "mdb-react-ui-kit";

const FeatureSection = ({
  title,
  visible = true,
  messageWhenHidden = null,
  className = "mb-3",
  children,
}) => {
  return (
    <MDBRow className={className}>
      <hr className="hr" />
      <MDBTypography variant="h6" className="mb-3 fw-bold">
        {title}
      </MDBTypography>
      {visible
        ? children
        : (
          <MDBTypography className="text-muted fst-italic">
            {messageWhenHidden}
          </MDBTypography>
        )}
    </MDBRow>
  );
};

export default FeatureSection;
