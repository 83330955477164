import { useEffect, useState } from "react";
import {
  convertISOToReadableDate,
  getDupSubCorpus,
  getDupSubCorpusStatisticsPerWeek,
  showErrorToast,
} from "../../../helpers";
import { submissionsInCorpusColumns } from "../columns";
import DataTable from "../../../components/table/DataTable";
import { LineChart } from "../../../components";

const SubmissionsInCorpusTab = () => {
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dupSubCorpusResponse, dupSubCorpusStatsResponse] =
          await Promise.all([
            getDupSubCorpus(),
            getDupSubCorpusStatisticsPerWeek(),
          ]);

        setData(dupSubCorpusResponse.data?.statistics ?? []);

        const statsData = dupSubCorpusStatsResponse.data ?? [];
        const formattedChartData = statsData.map((item) => {
          const formattedItem = {
            date: convertISOToReadableDate(item.date, "MM-DD"),
          };
          item.indexed_submissions.forEach((submission) => {
            formattedItem[submission.name] = submission.count;
          });
          return formattedItem;
        });

        setChartData(formattedChartData);
      } catch (error) {
        showErrorToast();
      }
    };

    fetchData();
  }, []);

  const chartKeys = Array.from(new Set(chartData.flatMap(Object.keys))).filter(
    (key) => key !== "date"
  );

  return (
    <>
      <LineChart data={chartData} keys={chartKeys} />
      <DataTable columns={submissionsInCorpusColumns} rows={data} />
    </>
  );
};

export default SubmissionsInCorpusTab;
