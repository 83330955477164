import { useEffect, useState, useCallback } from "react";
import { showErrorToast, getOnDemandScreeningRecent, convertISOToReadableDate } from "../../../helpers";
import DataTable from "../../../components/table/DataTable";
import { recentActivityColumns } from "../columns";
import { MDBBtn } from "mdb-react-ui-kit";

const RecentActivityTab = () => {
    const [data, setData] = useState([]);

    const [lastEventTimestamp, setLastEventTimestamp] = useState('');

    const [hasMoreData, setHasMoreData] = useState(true);

    const initialLoadCount = 100;

    useEffect(() => {
        fetchData(initialLoadCount);
    }, []);

    const fetchData = async (limit, lastTimestamp = '') => {
        try {
            const { data: fetchedData } = await getOnDemandScreeningRecent(limit, lastTimestamp);
            const mappedData = fetchedData.map(item => ({
                ...item,
                timestamp: convertISOToReadableDate(item.eventTimestamp)
            }));
            setData(prevData => lastTimestamp === '' ? mappedData : [...prevData, ...mappedData]);

            if (mappedData.length > 0) {
                setLastEventTimestamp(mappedData[mappedData.length - 1].eventTimestamp);
            }

            if (mappedData.length < limit) {
                setHasMoreData(false);
            }

        } catch (error) {
            showErrorToast();
        }
    };

    const loadMoreData = useCallback(async () => {
        await fetchData(initialLoadCount, lastEventTimestamp);
    }, [lastEventTimestamp]);

    return (
        <>
            <DataTable
                columns={recentActivityColumns}
                rows={data}
            />
            {hasMoreData && (
                <div className="d-flex justify-content-center mt-3">
                    <MDBBtn onClick={loadMoreData}>
                        Load more data
                    </MDBBtn>
                </div>
            )}
        </ >
    );
};

export default RecentActivityTab;