import { useState, useCallback, useEffect } from 'react';
import * as cactusSettingsService from '../services/cactus-settings.service';
import { showErrorToast, showSuccessToast } from '../../../helpers';
import { itemsPerPage as ITEMS_PER_PAGE } from '../../../constants';

export const useCactusSettings = ({ 
    initialFetch = true,
    itemsPerPage = ITEMS_PER_PAGE
} = {}) => {
    const [settingEntries, setSettingEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [fetchParams, setFetchParams] = useState({
        currentPage: 0,
        searchTerm: '',
    });

    const fetchEntries = useCallback(async (loadAll = false) => {
        setLoading(true);
        setError(false);

        const { currentPage, searchTerm } = fetchParams;
        let response;
        try {
            if (loadAll) {
                response = await cactusSettingsService.fetchEntries();
                setSettingEntries(response.cactus_settings);
                setPageCount(1);
            } else {
                const offset = currentPage * itemsPerPage;
                response = await cactusSettingsService.fetchEntries(offset + 1, itemsPerPage, searchTerm);
                setSettingEntries(response.cactus_settings);
                setPageCount(Math.ceil(response.total / itemsPerPage));
            }
        } catch (err) {
            setError(true);
            showErrorToast('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    }, [fetchParams, itemsPerPage]);

    const createEntry = async (entryData) => {
        try {
            setLoading(true);
            await cactusSettingsService.createEntry(entryData);
            showSuccessToast('Entry created successfully');
        } catch (err) {
            showErrorToast('Failed to create entry');
            throw err;
        } finally {
            setLoading(false)
        }
    };

    const updateEntry = async (entryId, entryData) => {
        try {
            setLoading(true);
            await cactusSettingsService.updateEntry(entryId, entryData);
            showSuccessToast('Entry updated successfully');
        } catch (err) {
            showErrorToast('Failed to update entry');
            throw err;
        } finally {
            setLoading(false)
        }
    };

    const deleteEntry = async (entryId) => {
        try {
            setLoading(true);
            await cactusSettingsService.deleteEntry(entryId);

            const isLastItemOnPage = settingEntries.length === 1;
            const isNotFirstPage = fetchParams.currentPage > 0;
            if (isLastItemOnPage && isNotFirstPage) {
                setFetchParams(prev => ({
                    ...prev,
                    currentPage: prev.currentPage - 1
                }));
            }

            showSuccessToast('Entry deleted successfully');
        } catch (err) {
            showErrorToast('Failed to delete entry');
            throw err;
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (initialFetch) {
            fetchEntries();
        }
    }, [fetchParams, fetchEntries, initialFetch]);

    const updateFetchParams = (newParams) => {
        setFetchParams(prev => ({
            ...prev,
            ...newParams,
            currentPage: newParams.currentPage !== undefined ? newParams.currentPage : prev.currentPage
        }));
    };

    return {
        settings: settingEntries,
        loading,
        error,
        pageCount,
        searchTerm: fetchParams.searchTerm,
        setSearchTerm: (term) => updateFetchParams({ searchTerm: term, currentPage: 0 }),
        currentPage: fetchParams.currentPage,
        setCurrentPage: (page) => updateFetchParams({ currentPage: page }),
        fetchEntries,
        createEntry,
        updateEntry,
        deleteEntry
    };
};
