import { axiosInstance } from "./axiosSetup";
import { API_PATHS } from "./apiPaths";

export const getDupSubCorpus = async () => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_CORPUS);
};

export const getDupSubRequests = async (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_REQUESTS(timeRange));
};

export const getDupSubRecent = async (limit = 0, lastEventTimestamp = "") => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_RECENT(limit, lastEventTimestamp));
};

export const getDupSubMatchLog = async () => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_MATCH_LOG);
};

export const getDupSubStatisticsPerWeek = () => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_STATISTICS_PER_WEEK);
};

export const getDupSubCorpusStatisticsPerWeek = () => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_CORPUS_STATISTICS_PER_WEEK);
};

export const getOnDemandScreeningPublishers = async () => {
  return axiosInstance.get(API_PATHS.GET_ON_DEMAND_SCREENING_PUBLISHERS);
};

export const getOnDemandScreeningSubmitted = async (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_ON_DEMAND_SCREENING_SUBMITTED(timeRange));
};

export const getOnDemandScreeningMonthly = async () => {
  return axiosInstance.get(API_PATHS.GET_ON_DEMAND_SCREENING_STATISTICS_PER_WEEK);
};

export const getOnDemandScreeningRecent = async (limit = 0, lastEventTimestamp) => {
  return axiosInstance.get(API_PATHS.GET_ON_DEMAND_SCREENING_RECENT(limit, lastEventTimestamp));
};

export const getOnDemandScreeningActionStats = async (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_ON_DEMAND_SCREENING_ACTION_STATS(timeRange));
};

export const getOnDemandScreeningStatisticsPerWeek = () => {
  return axiosInstance.get(API_PATHS.GET_ON_DEMAND_SCREENING_STATISTICS_PER_WEEK);
};

export const getAmbientScreeningPublishers = async () => {
  return axiosInstance.get(API_PATHS.GET_AMBIENT_SCREENING_PUBLISHERS);
};

export const getAmbientScreeningSubmitted = async (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_AMBIENT_SCREENING_SUBMITTED(timeRange));
};

export const getAmbientScreeningMonthly = async () => {
  return axiosInstance.get(API_PATHS.GET_AMBIENT_SCREENING_STATISTICS_PER_WEEK);
};

export const getAmbientScreeningRecent = async (limit = 0, lastEventTimestamp) => {
  return axiosInstance.get(API_PATHS.GET_AMBIENT_SCREENING_RECENT(limit, lastEventTimestamp));
};

export const getAmbientScreeningActionStats = async (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_AMBIENT_SCREENING_ACTION_STATS(timeRange));
};

export const getAmbientScreeningJournals = async () => {
  return axiosInstance.get(API_PATHS.GET_AMBIENT_SCREENING_JOURNALS);
};

export const getOnAmbientScreeningStatisticsPerWeek = () => {
  return axiosInstance.get(API_PATHS.GET_AMBIENT_SCREENING_STATISTICS_PER_WEEK);
};

export const getApiAccessStatsExportUrl = async (period, publisher_id) => {
  return axiosInstance.get(API_PATHS.GET_API_ACCESS_STATS_EXPORT_URL(period, publisher_id));
};

export const getApiAccessRecentActivity = async (limit = 0, lastEventTimestamp) => {
  return axiosInstance.get(API_PATHS.GET_API_ACCESS_RECENT_ACTIVITY(limit, lastEventTimestamp));
};

export const getApiAccessRequests = async (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_API_ACCESS_REQUESTS(timeRange));
};

export const getCognitoUsers = async () => {
  return axiosInstance.get(API_PATHS.GET_COGNITO_USERS);
};

export const getDupSubJournals = async () => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_JOURNALS);
};

export const getDupSubMatchesAverage = (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_MATCHES_AVERAGE(timeRange));
};

export const getDupSubMatchesPerPublisher = (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_MATCHES_PER_PUBLISHER(timeRange));
};

export const getDupSubMatchesPerJournal = (timeRange) => {
  return axiosInstance.get(API_PATHS.GET_DUP_SUB_MATCHES_PER_JOURNAL(timeRange));
};

export const getPublisherProfilesMonitoring = () => {
  return axiosInstance.get(API_PATHS.GET_PUBLISHER_PROFILES_MONITORING);
};
