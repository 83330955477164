import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBSwitch,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import Select from "react-select";
import SwitchGroup from "./SwitchGroup";
import FeatureSection from "./FeatureSection";
import useKeys from "../../keys/hooks/useKeys";
import { CREATE_USER_PASSWORD_SETUP_OPTIONS } from "../../../constants";

const PublisherReviewForm = ({ data, disabledSections = {} }) => {
  const { keysData } = useKeys({ itemsPerPage: Number.MAX_SAFE_INTEGER });

  const keyOptions = keysData?.map((key) => ({
    value: key.id?.toString(),
    label: key.key_name,
  }));

  return (
    <div>
      <MDBTypography variant="h4" className="mb-3">
        Submission preview
      </MDBTypography>
      <MDBTypography variant="h5">Publisher</MDBTypography>
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            disabled
            value={data?.publisherForm?.name ?? ""}
            label="Name"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol md={6}>
          <MDBTypography variant="h5">Profile</MDBTypography>
          <MDBInput
            disabled
            value={data?.publisherProfileForm?.profile_name ?? ""}
            label="Name"
          />
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h5" className="mb-3">
            Applications
          </MDBTypography>
          <SwitchGroup>
            <MDBSwitch
              checked={data?.publisherProfileForm.components?.PMChk ?? false}
              disabled
              label="On-Demand Screening"
            />
            <MDBSwitch
              checked={data?.publisherProfileForm.components?.AMBScr ?? false}
              disabled
              label="Ambient Screening"
            />
            <MDBSwitch
              checked={data?.publisherProfileForm.components?.WTLst ?? false}
              disabled
              label="Watchlist"
            />
          </SwitchGroup>
        </MDBCol>
      </MDBRow>
      <MDBTypography variant="h5" className="mb-3">
        Tools & signals
      </MDBTypography>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            PubPeer
          </MDBTypography>
          <SwitchGroup className="mb-2">
            <MDBSwitch
              checked={data?.publisherProfileForm.components?.FOCRat ?? false}
              disabled
              label="PubPeer hits"
            />
            <div>
              <label className="grey-text">PubPeer API Key</label>
              <Select
                key={data?.publisherProfileForm?.service_keys?.pubPeerAPIKey}
                placeholder="Select key"
                options={keyOptions}
                value={keyOptions.find(
                  (element) =>
                    element?.value ===
                    data?.publisherProfileForm?.service_keys?.pubPeerAPIKey?.toString()
                )}
                isDisabled
              />
            </div>
          </SwitchGroup>
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            ClearSkies Papermill Alarm: Public
          </MDBTypography>
          <SwitchGroup className="mb-2">
            <MDBSwitch
              disabled
              label="Papermill similarity"
              checked={data?.publisherProfileForm?.components?.CSStat ?? false}
            />
            <div>
              <label className="grey-text">ClearSkies API Key</label>
              <Select
                key={data?.publisherProfileForm?.service_keys?.clearSkiesAPIKey}
                placeholder="Select key"
                options={keyOptions}
                value={keyOptions.find(
                  (element) =>
                    element?.value ===
                    data?.publisherProfileForm?.service_keys?.clearSkiesAPIKey?.toString()
                )}
                isDisabled
              />
            </div>
          </SwitchGroup>
        </MDBCol>
      </MDBRow>
      <FeatureSection
        title="Cactus Integrity Checks"
        messageWhenHidden="Cactus Integrity Checks are not available for this publisher"
        visible={!disabledSections.cactusIntegrityChecks}
      >
        <MDBCol>
          <SwitchGroup>
            <MDBSwitch
              label="Author affiliation relevance"
              checked={data?.publisherProfileForm?.components?.cactusTool?.authorAffiliationRelevance ?? false}
              disabled
            />
            <MDBSwitch
              label="Author geographical distribution"
              checked={data?.publisherProfileForm?.components?.cactusTool?.authorGeographicalDistribution ?? false}
              disabled
            />
            <MDBSwitch
              label="Age of reference"
              checked={data?.publisherProfileForm?.components?.cactusTool?.ageOfReference ?? false}
              disabled
            />
            <MDBSwitch
              label="Reference citation"
              checked={data?.publisherProfileForm?.components?.cactusTool?.referenceCitation ?? false}
              disabled
            />
          </SwitchGroup>
        </MDBCol>
        <MDBCol>
          <SwitchGroup>
            <MDBSwitch
              label="Citation cartel"
              checked={data?.publisherProfileForm?.components?.cactusTool?.citationCartel ?? false}
              disabled
            />
            <MDBSwitch
              label="Self citation"
              checked={data?.publisherProfileForm?.components?.cactusTool?.selfCitation ?? false}
              disabled
            />
            <MDBSwitch
              label="Unrelated references"
              checked={data?.publisherProfileForm?.components?.cactusTool?.unrelatedReferences ?? false}
              disabled
            />
          </SwitchGroup>
        </MDBCol>
      </FeatureSection>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Reference analysis
          </MDBTypography>
          <SwitchGroup>
            <MDBSwitch
              disabled
              label="Retraction Watch"
              checked={data?.publisherProfileForm?.components?.RWtch ?? false}
            />
            <MDBSwitch
              disabled
              label="DOI analysis"
              checked={data?.publisherProfileForm?.components?.CRRF ?? false}
            />
          </SwitchGroup>
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Text analysis
          </MDBTypography>
          <SwitchGroup>
            <MDBSwitch
              disabled
              label="Tortured phrases"
              checked={data?.publisherProfileForm?.components?.TPHrs ?? false}
            />
            <MDBSwitch
              disabled
              label="GenAI response"
              checked={
                data?.publisherProfileForm?.components?.genAIResponse ?? false
              }
            />
            <MDBSwitch
              disabled
              label="Unnatural text"
              checked={data?.publisherProfileForm?.components?.MLDet ?? false}
            />
          </SwitchGroup>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Metadata analysis
          </MDBTypography>
          <MDBSwitch
            disabled
            checked={
              data?.publisherProfileForm?.components?.wordFileProperties ??
              false
            }
            label="Word file metadata"
          />
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Duplicate submissions
          </MDBTypography>
          <MDBSwitch
            disabled
            checked={data?.publisherProfileForm?.components?.OPScr ?? false}
            label="Metadata screening"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Watchlist
          </MDBTypography>
          <SwitchGroup>
            <MDBSwitch
              disabled
              label="Fake affiliation names"
              checked={data?.publisherProfileForm.components?.SSPan ?? false}
            />
            <MDBSwitch
              disabled
              label="Fake names or e-mail addresses"
              checked={data?.publisherProfileForm.components?.SSPidd ?? false}
            />
            <MDBSwitch
              disabled
              label="Fake e-mail domains"
              checked={data?.publisherProfileForm.components?.SSPfd ?? false}
            />
            <MDBSwitch
              disabled
              label="Disposable e-mail domains"
              checked={data?.publisherProfileForm?.components?.SSPdd ?? false}
            />
          </SwitchGroup>
        </MDBCol>
        <MDBCol>
          <SwitchGroup>
            <MDBSwitch
              disabled
              label="Bad actors"
              checked={data?.publisherProfileForm?.components?.SSPba ?? false}
            />
            <MDBSwitch
              disabled
              label="Suspect articles"
              checked={data?.publisherProfileForm?.components?.SSPsa ?? false}
            />
            <MDBSwitch
              disabled
              label="Manuscripts offered for sale"
              checked={data?.publisherProfileForm?.components?.SSPmfs ?? false}
            />
            <MDBSwitch
              disabled
              label="Metadata suspects"
              checked={data?.publisherProfileForm?.components?.SSMeta ?? false}
            />
          </SwitchGroup>
        </MDBCol>
      </MDBRow>
      <hr className="hr" />
      <MDBTypography variant="h5">User</MDBTypography>
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            disabled
            value={data?.publisherUserForm?.email ?? ""}
            label="Email"
          />
        </MDBCol>
        <MDBCol md={6}>
          <Select
            key={data?.publisherUserForm.passwordSetupType}
            options={CREATE_USER_PASSWORD_SETUP_OPTIONS}
            value={CREATE_USER_PASSWORD_SETUP_OPTIONS.find(
              (element) =>
                element?.value === data?.publisherUserForm?.passwordSetupType
            )}
            isDisabled
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBCheckbox
            disabled
            label="Is Publisher Admin"
            checked={data?.publisherUserForm?.publisherSupervisoryRole}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md={6}>
          <MDBCheckbox
            disabled
            label="Is Admin"
            checked={data?.publisherUserForm.isAdminGroup}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default PublisherReviewForm;
