import * as XLSX from "xlsx";
import { requestsExtendedViewColumns } from "./columns";

export const exportToExcel = (mappedData, fileName) => {
    if (!mappedData || mappedData.length === 0) {
        console.warn("No data to export.");
        return;
    }

    const exportableData = mappedData.map(row => {
        const formattedRow = {};
        requestsExtendedViewColumns.forEach(col => {
            formattedRow[col.label] = row[col.field];
        });
        return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "API Access Requests");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};