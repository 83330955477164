import { axiosInstance } from "./axiosSetup";
import { API_PATHS } from './apiPaths';

export const getProfiles = (startrow = 1, maxrows = 10, search = '') => {
    return axiosInstance.get(API_PATHS.GET_PROFILES(startrow, maxrows, search));
};

export const getProfile = (profileId) => {
    return axiosInstance.get(API_PATHS.GET_PROFILE(profileId));
};

export const createProfile = (profileData) => {
    return axiosInstance.post(API_PATHS.CREATE_PROFILE, profileData);
};

export const updateProfile = (profileId, profileData) => {
    return axiosInstance.patch(API_PATHS.UPDATE_PROFILE(profileId), profileData);
};

export const deleteProfile = (profileId) => {
    return axiosInstance.delete(API_PATHS.DELETE_PROFILE(profileId));
};
