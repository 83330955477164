import React, {useState} from 'react';
import {MDBBtn, MDBCol, MDBRow, MDBTextArea} from "mdb-react-ui-kit";
import {extractManuscriptForSaleManual, showErrorToast, uploadManuscriptForSaleManual} from "../../../helpers";
import {toast} from "react-toastify";
import {notificationsTime} from "../../../constants";

const MANUSCRIPT_FOR_SALE_SUSPECT_TYPE = 7;

const ManuscriptForSaleWatchlistForm = ({ fetchWatchlist }) => {
    const [extractedData, setExtractedData] = useState(null);

    const [suspectContent, setSuspectContent] = useState('');

    const handleExtractData = async () => {
        if (!suspectContent) {
            showErrorToast('Suspect content should not be empty')
            return;
        }
        const res = await extractManuscriptForSaleManual(suspectContent);
        setExtractedData(JSON.stringify(res.data, null, 2));
    };

    const handleUploadClick = async () => {
        if (!extractedData) {
            showErrorToast('Extract data first');
            return;
        }

        try {
            await uploadManuscriptForSaleManual(extractedData);
            fetchWatchlist(MANUSCRIPT_FOR_SALE_SUSPECT_TYPE, 1);
        } catch (e) {
            const errorMessage = e.response.data.errorMessage;
            if (errorMessage.includes('duplicate key value violates unique constraint')) {
                showErrorToast('Item with this title already exists');
            }
        }
    }

    return (
        <>
            <MDBRow className="mt-3 mb-5">
                <MDBCol lg={10}>
                    <MDBTextArea
                        rows={4}
                        label='Suspect content'
                        onChange={(e) => setSuspectContent(e.target.value)}
                        value={suspectContent}
                        required={true}
                        className={`form-control`}
                    />
                </MDBCol>
                <MDBCol className={'d-flex flex-lg-column flex-row mt-3 mt-lg-0'}>
                    <MDBBtn
                        color="secondary"
                        size="md"
                        className={'me-lg-0 me-3'}
                        onClick={handleExtractData}
                    >
                        Extract data
                    </MDBBtn>
                    <MDBBtn
                        className={'mt-lg-2'}
                        color="primary"
                        size="md"
                        onClick={handleUploadClick}
                    >
                        Upload to watchlist
                    </MDBBtn>
                </MDBCol>
            </MDBRow>

            {extractedData &&
                (<MDBRow className="mb-5">
                    <h5>Extracted data:</h5>
                    <MDBCol
                        className={'bg-light position-relative overflow-auto'}
                        style={{ maxHeight: 300 }}
                    >
                        <code>
                            <pre>{extractedData}</pre>
                        </code>
                    </MDBCol>
                </MDBRow>)
            }
        </>
    );
};

export default ManuscriptForSaleWatchlistForm;
